import { Paper, Typography, Button } from "@material-ui/core";
import {
  Add as AddIcon,
  CloudDownload as CloudDownloadIcon,
} from "@material-ui/icons";
import TvIcon from '@material-ui/icons/Tv';
import React, { useEffect, useState } from "react";
import MediaCampaignDetailsRow from "./MediaCampaignDetailsRow";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { currencyFormat } from "../../../utils/GeneralUtils";
import MonthRangePicker from "../../Shared/MonthRangePicker";
import axios from "axios";
import MediaCampaignsFilter from "./MediaCampaignsFilter";

const MediaSpendAllocationTable = ({
  mediaCampaigns,
  isLoading,
  handleSelectCampaign,
  budgetItems,
  setSnackBarProps,
  handleUpdateMediaSpend,
  fetchAllBudget,
  handleAddNewMedia,
  mediaSuppliers,
  company,
  handleKeywordChange,
  keyword,
  originalMediaCampaigns,
  handleOpenTvSpotlistDialog
}) => {
  const [datesToDisplay, setDatesToDisplay] = useState([]);
  const [totalSpendByMonth, setTotalSpendByMonth] = useState(null);
  const [isClientView, setIsClientView] = useState(true);

  useEffect(() => {
    populateDatesToDisplay();
  }, []);

  useEffect(() => {
    if (datesToDisplay?.length > 0 && mediaCampaigns?.length > 0) {
      setTotalSpendByMonth(calculateTotalMediaSpendByMonth());
    }
  }, [datesToDisplay, mediaCampaigns]);

  const populateDatesToDisplay = () => {
    let _datesToDisplay = [];
    for (let i = -2; i <= 3; i++) {
      const month = moment().clone().add(i, "months");
      _datesToDisplay.push(month);
    }
    setDatesToDisplay(_datesToDisplay);
  };

  const calculateTotalMediaSpendByMonth = () => {
    return mediaCampaigns?.reduce((totalSpendByMonth, mediaCampaign) => {
      return mediaCampaign.mediaSpend?.reduce((totalByCampaign, mediaSpend) => {
        const dateKey = moment(mediaSpend.billingDate).format("MMM YY");
        
        if (datesToDisplay.find((d) => d.format("MMM YY") === dateKey)) {
          totalByCampaign["client-"+dateKey] = (totalByCampaign["client-"+dateKey] || 0) + mediaSpend.total;
          totalByCampaign["supplier-"+dateKey] = (totalByCampaign["supplier-"+dateKey] || 0) + mediaSpend.totalWithoutCommission;
        }
        return totalByCampaign;
      }, totalSpendByMonth || {}); // Set empty object as initial value
    }, {}); // Set empty object as initial value
  };

  const handleRangeChange = (startDate, endDate) => {
    let _datesToDisplay = [];
    const monthsBetween = moment(endDate).diff(
      moment(startDate),
      "months",
      true
    );
    for (let i = 0; i <= monthsBetween; i++) {
      const month = moment(startDate).clone().add(i, "months");
      _datesToDisplay.push(month);
    }
    setDatesToDisplay(_datesToDisplay);
  };

  // calculates the total margin based on supplier rebate or company default commission.
  // the commission will then be deducted from the total in order to reconcile bills from suppliers.
  // default commission per client is 15% this may change in the future.
  const calculateCommissionRate = (mediaCampaign) => {
    return mediaCampaign.mediaSupplier?.marginRebate !== undefined &&
      mediaCampaign.mediaSupplier?.marginRebate !== null
      ? mediaCampaign.mediaSupplier?.marginRebate
      : company?.mediaAdminFee
      ? company.mediaAdminFee
      : 15;
  };

  return (
    <>
      {isLoading ? (
        <div style={{ display: "grid" }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
        </div>
      ) : (
        <div className="media-spend-allocation-root">
          {originalMediaCampaigns?.length === 0 ? (
            <div
              className="media-spend-allocation-paper"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                padding: "20px 30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <strong>No Media Campaigns Found</strong>
                </Typography>
              </div>
              <Button
                className="default-button  add-budget-button"
                size="small"
                onClick={handleAddNewMedia}
                startIcon={<AddIcon />}
              >
                Add media
              </Button>
            </div>
          ) : (
            <>
              <div className="media-spend-allocation-header-container">
                <Typography>Campaign Name</Typography>
                <Typography>Platform</Typography>
                <Typography>PO Number</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Typography>Billing Month</Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      gap: 15,
                    }}
                  >
                    <MonthRangePicker
                      value={[
                        datesToDisplay[0]?.toDate(),
                        datesToDisplay[datesToDisplay.length - 1].toDate(),
                      ]}
                      handleOnChangeRange={handleRangeChange}
                    />
                    <Button
                      onClick={() => setIsClientView(!isClientView)}
                      className={`default-button-grey default-button-typography ${isClientView ? "" : "media-spend-budget-allocation-input-supplier-cost-view"}`}
                    >
                      {isClientView
                        ? "Client View"
                        : "Supplier Cost"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="media-spend-allocation-paper">
                <div className="media-spend-allocation-body-container">
                  {/* {!isClientView && (
                    <div className="media-spend-allocation-container-title">
                      <Typography>Supplier Cost</Typography>
                    </div>
                  )} */}
                  <MediaCampaignsFilter
                    keyword={keyword}
                    setKeyword={handleKeywordChange}
                  />
                  <div className="media-spend-allocation-row no-hover-state">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="media-spend-butget-column-container media-spend-allocation-container">
                      {datesToDisplay.map((date, index) => (
                        <div
                          key={index}
                          className={`budget-allocation-container`}
                        >
                          <Typography className={`budget-label-container ${isClientView ? "" : "media-spend-budget-allocation-cost-view"}`}>
                            {date.format("MMM YY")}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                  {mediaCampaigns.map((mediaCampaign, index) => (
                    <MediaCampaignDetailsRow
                      key={index}
                      mediaCampaign={mediaCampaign}
                      handleSelectCampaign={handleSelectCampaign}
                      budgetItems={budgetItems}
                      setSnackBarProps={setSnackBarProps}
                      handleUpdateMediaSpend={handleUpdateMediaSpend}
                      fetchAllBudget={fetchAllBudget}
                      datesToDisplay={datesToDisplay}
                      isClientView={isClientView}
                      commissionRate={calculateCommissionRate(mediaCampaign)}
                    />
                  ))}
                  {totalSpendByMonth && (
                    <div className="media-spend-allocation-row no-hover-state">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div className="media-spend-butget-column-container media-spend-allocation-container">
                        {datesToDisplay.map((date, index) => (
                          <div
                            key={index}
                            className={`budget-allocation-container`}
                          >
                            <Typography className={`budget-label-container ${isClientView ? "" : "media-spend-budget-allocation-cost-view"}`}>
                              {totalSpendByMonth[(isClientView ? "client-" : "supplier-") + date.format("MMM YY")]
                                ? currencyFormat(
                                    Number(
                                      totalSpendByMonth[(isClientView ? "client-" : "supplier-") +date.format("MMM YY")]
                                    )
                                  )
                                : ""}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div>
                    <div>
                      <Button
                        className="default-button  add-budget-button"
                        size="small"
                        onClick={handleAddNewMedia}
                        startIcon={<AddIcon />}
                      >
                        Add media
                      </Button>
                      <Button
                        className="default-button download-as-excel-button"
                        size="small"
                        onClick={() => {
                          axios
                            .get(
                              `MediaCampaign/DownloadMediaPlan/${
                                company.id
                              }/${datesToDisplay[0].format(
                                "YYYY-MM-DD"
                              )}/${datesToDisplay[
                                datesToDisplay.length - 1
                              ].format(
                                "YYYY-MM-DD"
                              )}?isClientCost=${isClientView}`,
                              { responseType: "blob" }
                            )
                            .then((response) => {
                              const url = window.URL.createObjectURL(
                                new Blob([response.data])
                              );
                              const link = document.createElement("a");
                              link.href = url;
                              link.setAttribute(
                                "download",
                                `${company.name}_${datesToDisplay[0].format(
                                  "DD-MM-YYYY"
                                )}-${datesToDisplay[
                                  datesToDisplay.length - 1
                                ].format("DD-MM-YYYY")}` + ".xlsx"
                              );
                              document.body.appendChild(link);
                              link.click();
                            });
                        }}
                        startIcon={<CloudDownloadIcon />}
                      >
                        Download as Excel
                      </Button>
                      <Button
                        className="default-button  add-budget-button"
                        size="small"
                        onClick={handleOpenTvSpotlistDialog}
                        startIcon={<TvIcon />}
                      >
                        TV Spotlist
                      </Button>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MediaSpendAllocationTable;
