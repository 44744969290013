import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { filterNumbersOnlyInput } from "../../../../utils/GeneralUtils";

const ReceiptForm = ({
  companies,
  payable,
  handleUpdateLocalPayable,
  staff,
  projects,
  handleUpdateMultiplePropertiesLocalPayable,
}) => {
  const handleChangePerson = (e, newValue) => {
    console.log("handleChangePerson", newValue);
    if (newValue?.userId != null) {
      //handleUpdateLocalPayable("userId", newValue?.id);
      handleUpdateMultiplePropertiesLocalPayable([
        {
          field: "userId",
          value: newValue?.id,
        },
        {
          field: "userEmail",
          value: newValue?.userEmail,
        },
        {
          field: "personId",
          value: null,
        },
      ]);
    } else if (newValue?.personId != null) {
      //handleUpdateLocalPayable("personId", newValue?.id);
      handleUpdateMultiplePropertiesLocalPayable([
        {
          field: "personId",
          value: newValue?.id,
        },
        {
          field: "userId",
          value: null,
        },
        {
          field: "userEmail",
          value: null,
        },
      ]);
    }
  };

  const GstRateCodeCheckbox = () => {
    return (
      <div
        style={{ justifyContent: "center", width: "100%" }}
        className="gst-section"
      >
        <FormControlLabel
          control={
            <Checkbox
              name="gst-checkbox"
              checked={payable.gstRateCode === "INPUT2"}
              onChange={(e) =>
                handleUpdateLocalPayable("gstRateCode", "INPUT2")
              }
              size="small"
            />
          }
          label="GST Inc"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="no-gst-checkbox"
              checked={payable.gstRateCode === "NONE"}
              onChange={(e) => handleUpdateLocalPayable("gstRateCode", "NONE")}
              size="small"
            />
          }
          label="No GST"
        />
      </div>
    );
  };

  useEffect(() => {
    if (payable.gstRateCode === "NONE") {
      handleUpdateLocalPayable("subtotal", payable.total);
    } else if (payable.gstRateCode === "INPUT2") {
      handleUpdateLocalPayable(
        "subtotal",
        (payable.total - (payable.total * 3) / 23).toFixed(2)
      );
    }
  }, [payable.gstRateCode]);

  return (
    <div className="payable-media-receipt-form-container">
      <Autocomplete
        className="person"
        options={staff}
        value={staff.find(
          (s) =>
            (s.userEmail !== null && s.userEmail === payable.userEmail) ||
            (s.personId !== null && s.personId === payable.personId)
        )}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        onChange={(e, newValue) => {
          handleChangePerson(e, newValue);
        }}
        selectOnFocus
        renderOption={(option) => {
          return (
            <li key={option.id}>{`${option.firstName} ${option.lastName}${
              option.userId != null ? " (Staff)" : ""
            }`}</li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Person"
            variant="filled"
            size="small"
            fullWidth={true}
            helperText={
              payable.userId == 64 ? (
                <Typography variant="caption">{`By ${payable.submittedBy}`}</Typography>
              ) : (
                ""
              )
            }
          />
        )}
      />
      <Autocomplete
        className="company"
        options={companies}
        value={companies.find((c) => c.id === payable.companyId)}
        getOptionLabel={(option) => option.name}
        onChange={(e, newValue) => {
          handleUpdateMultiplePropertiesLocalPayable([
            {
              field: "companyId",
              value: newValue,
            },
            {
              field: "projectId",
              value: null,
            },
          ]);
        }}
        selectOnFocus
        renderOption={(option) => {
          return <li key={option.id}>{option.name}</li>;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Client"
            variant="filled"
            size="small"
            fullWidth={true}
          />
        )}
      />
      <div className="date">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label={"Date"}
            value={payable.date}
            format="dd/MM/yyyy"
            onChange={(date) =>
              handleUpdateLocalPayable(
                "date",
                moment(date).format("YYYY-MM-DD")
              )
            }
            fullWidth
            inputVariant={"filled"}
            size="small"
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="total">
        <TextField
          variant="filled"
          label={payable.gstRateCode !== "NONE" ? "NZD Gst Inc" : "Total"}
          fullWidth
          value={payable.total}
          onChange={(e) =>
            handleUpdateLocalPayable(
              "total",
              filterNumbersOnlyInput(e.target.value)
            )
          }
          size="small"
        />
        {payable.gstRateCode !== "NONE" && (
          <TextField
            variant="filled"
            label="Subtotal"
            disabled
            fullWidth
            value={payable.subtotal}
            size="small"
          />
        )}
      </div>
      {GstRateCodeCheckbox()}
      <Autocomplete
        className="project"
        options={
          payable.companyId
            ? projects.filter((p) => p.companyFk == payable.companyId)
            : projects
        }
        value={
          payable.projectId
            ? projects.find((p) => p.id === payable.projectId)
            : null
        }
        getOptionLabel={(option) => option.projectName}
        onChange={(e, newValue) => {
          handleUpdateLocalPayable("projectId", newValue?.id);
        }}
        selectOnFocus
        renderOption={(option) => {
          return (
            <li key={option.id}>
              {option.projectTag} - {option.projectName}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Project"
            variant="filled"
            size="small"
            fullWidth={true}
          />
        )}
      />
      <TextField
        className="supplier"
        variant="filled"
        label="Supplier"
        fullWidth
        value={payable.mediaSupplierName}
        onChange={(e) =>
          handleUpdateLocalPayable("mediaSupplierName", e.target.value)
        }
        size="small"
      />
      <TextField
        className="description"
        variant="filled"
        label="What is this for?"
        fullWidth
        value={payable.description}
        onChange={(e) =>
          handleUpdateLocalPayable("description", e.target.value)
        }
        size="small"
      />
    </div>
  );
};

export default ReceiptForm;
