import React, { useEffect, useState } from "react";
import "./mediaCampaignInvoice.scss";
import { Button, TextField, Typography, CircularProgress } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import GenerateMediaInvoiceDialog from "./GenerateMediaInvoiceDialog";
import axios from "axios";
import moment from "moment";
import Snacky from "../../../Shared/Snacky";
import MediaCampaignInvoiceTable from "./MediaCampaignInvoiceTable";
import { get3monthsBeforeAndAfterCurrentDate } from "../../../../utils/TimeHelpers";
import ConfirmationDialogActions from "../../../Shared/ConfirmationDialogActions";
import AdStreamDeliverablesDialog from "../MediaDeliveries/AdStreamDeliverablesDialog";
import AccountContactDetailsDialog from "./AccountContactDetailsDialog";
import SendSingleClientMediaInvoiceDialog from "./SendSingleClientMediaInvoiceDialog";
import ConfirmtionDialog from "../../../Shared/ConfirmationDialog";
import ProcessingSpinnerDialog from "../../../Shared/Spinner/ProcessingSpinnerDialog";

const MediaCampaignInvoice = ({ companyId, organisationId, company }) => {
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [mediaInvoices, setMediaInvoices] = useState([]);
  const [openGenerateInvoiceDialog, setOpenGenerateInvoiceDialog] =
    useState(false);
  const [isGeneratingInvoices, setIsGeneratingInvoices] = useState(false);
  const [selectedBillingMonth, setSelectedBillingMonth] = useState(moment());
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isUpdatingInvoices, setIsUpdatingInvoices] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [openAdStreamDeliverablesDialog, setOpenAdStreamDeliverablesDialog] =
    useState(false);
  const [openAccountContactDetails, setOpenAccountContactDetails] =
    useState(false);
  const [openConfirmSendEmailToClient, setOpenConfirmSendEmailToClient] =
    useState(false);
  const [isSendingInvoices, setIsSendingInvoices] = useState(false);
  const [openCannotGenerateDialog, setOpenCannotGenerateDialog] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [clientDeliveries, setClientDeliveries] = useState([]);
  const [loadingClientDeliveries, setLoadingClientDeliveries] = useState([]);

  useEffect(() => {
    if (selectedBillingMonth && companyId) {
      getMediaInvoices();
      getDeliveriesByClient();
    }
  }, [selectedBillingMonth]);

  const getDeliveriesByClient = () => {
    setLoadingClientDeliveries(true);
    axios.get(`AdStream/GetDeliveriesByClient/${companyId}/${selectedBillingMonth.format("M")}/${selectedBillingMonth.format("YYYY")}`)
      .then(({ data }) => {
        setClientDeliveries(data);
      }).catch(e => {
        console.log(e);
      }).finally(() => {
        setLoadingClientDeliveries(false);
      })
  }

  const updateReferenceMediaInvoice = (xeroInvoiceId, reference) => {
    var newMediaInvoices = mediaInvoices.map((mi) => {
      if (mi.xeroInvoiceId == xeroInvoiceId) {
        mi.reference = reference;
      }
      return mi;
    });
    setMediaInvoices(newMediaInvoices);
  };

  const handleToggleAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems([...mediaInvoices.map((invoice) => invoice.id)]);
    }
    setSelectAll(!selectAll);
  };

  const handleToggleItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const getMediaInvoices = () => {
    setIsLoadingInvoices(true);
    axios
      .get(
        `/MediaCampaign/GetMediaInvoices/${companyId}/${selectedBillingMonth.month() + 1
        }/${selectedBillingMonth.year()}`
      )
      .then(({ data }) => {
        setMediaInvoices(data);
      })
      .catch((err) => {
        console.log("Unable to pull invoices");
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to pull invoices.",
        });
      })
      .finally(() => setIsLoadingInvoices(false));
  };
  const handleGenerateInvoices = (
    selectedMonthForInvoice,
    includeAdStream = true
  ) => {
    setIsGeneratingInvoices(true);
    var body = null;
    if (includeAdStream) {
      body = clientDeliveries;
    } else {
      body = [];
    }
    axios
      .post(
        `/MediaCampaign/GenerateMediaInvoiceByClient/${companyId}/${selectedMonthForInvoice.month() + 1}/${selectedMonthForInvoice.year()}`,
        body
      )
      .then(({ data }) => {
        getMediaInvoices();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! The invoices have been generated.",
        });
        setOpenGenerateInvoiceDialog(false);
      })
      .catch((err) => {
        console.log(err.response);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: err.response.data,
        });
      })
      .finally(() => setIsGeneratingInvoices(false));
  };

  const handleGenerateDeliveryInvoices = (
    selectedMonthForInvoice,
    clientDeliveries,
  ) => {
    setIsGeneratingInvoices(true);
    axios
      .post(
        `/MediaCampaign/GenerateDeliveryInvoicesFromMediaSpend/${companyId}/${selectedMonthForInvoice.month() + 1}/${selectedMonthForInvoice.year()}`,
        clientDeliveries
      )
      .then(({ data }) => {
        getMediaInvoices();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! The invoices have been generated.",
        });
        setOpenAdStreamDeliverablesDialog(false);
      })
      .catch((err) => {
        console.log(err.response);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: err.response.data,
        });
      })
      .finally(() => setIsGeneratingInvoices(false));
  };

  const handleUpdateInvoiceStatus = (status) => {
    setIsUpdatingInvoices(true);
    axios
      .post(
        `/mediaCampaign/UpdateMediaInvoiceStatus/${status}/${organisationId}`,
        selectedItems
      )
      .then(({ data }) => {
        getMediaInvoices();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Fantastic! The invoices have been updated.",
        });
        setOpenConfirmApprove(false);
        setOpenConfirmDelete(false);
        setSelectedItems([]);
        setSelectAll(false);
      })
      .catch((err) => {
        console.error(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to update invoices.",
        });
      })
      .finally(() => setIsUpdatingInvoices(false));
  };

  const handleDownloadInvoices = () => {
    setDownloading(true);
    var invoiceIds = [];
    for (var i = 0; i < mediaInvoices.length; i++) {
      if (selectedItems.includes(mediaInvoices[i].id)) {
        invoiceIds.push(mediaInvoices[i].xeroInvoiceId)
      }
    }
    axios
      .post(
        `/MediaCampaign/DownloadInvoices`, invoiceIds, {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
        },
      }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Invoice_${moment().format("DD-MM-YYYY")}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Successfully downloaded.",
        });
      })
      .catch((err) => {
        console.error(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to download invoices.",
        });
      })
      .finally(() => setDownloading(false));
  };

  const handleCloseSendSingleMediaDialog = () => {
    setOpenConfirmSendEmailToClient(false);
  };

  const handleSendMediaInvoice = () => {
    setIsSendingInvoices(true);
    axios
      .post(`/mediacampaign/SendMediaInvoices`, [
        {
          companyId: companyId,
          mediaInvoiceIds: selectedItems,
        },
      ])
      .then(({ data }) => {
        getMediaInvoices();
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Awesome! The invoices have been sent!",
        });
        handleCloseSendSingleMediaDialog();
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Something went wrong",
        });
      })
      .finally(() => setIsSendingInvoices(false));
  };
  return (
    <div style={{ display: "grid", gap: 10 }}>
      <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
      {downloading && (
        <ProcessingSpinnerDialog loading={downloading} />
      )}
      {openCannotGenerateDialog && (
        <ConfirmtionDialog
          show={openCannotGenerateDialog}
          titleText="Oops!"
          contentText="It like there are invoices for this month. Please delete those first before generating new invoices"
          handleClose={() => setOpenCannotGenerateDialog(false)}
          buttonText="Ok"
        />
      )}
      {openGenerateInvoiceDialog && (
        <GenerateMediaInvoiceDialog
          open={openGenerateInvoiceDialog}
          handleClose={(e) => setOpenGenerateInvoiceDialog(false)}
          handleGenerateInvoices={handleGenerateInvoices}
          isGeneratingInvoices={isGeneratingInvoices}
          companyId={companyId}
          selectedBillingMonth={selectedBillingMonth}
          setSelectedBillingMonth={setSelectedBillingMonth}
          loadingClientDeliveries={loadingClientDeliveries}
        />
      )}
      {openAccountContactDetails && (
        <AccountContactDetailsDialog
          open={openAccountContactDetails}
          handleClose={(e) => setOpenAccountContactDetails(false)}
          companyId={companyId}
          setSnackBarProps={setSnackBarProps}
        />
      )}
      {openConfirmDelete && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openConfirmDelete}
          contentText={"This action will delete the selected invoices"}
          button1Action={() => setOpenConfirmDelete(false)}
          button1Text={"Cancel"}
          button2Text={"Delete"}
          isLoading={isUpdatingInvoices}
          button2Action={(e) => handleUpdateInvoiceStatus("DELETED")}
        />
      )}
      {openConfirmApprove && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openConfirmApprove}
          contentText={"This action will approve the selected invoices"}
          button1Action={() => setOpenConfirmApprove(false)}
          button1Text={"Cancel"}
          button2Text={"Approve"}
          isLoading={isUpdatingInvoices}
          button2Action={(e) => handleUpdateInvoiceStatus("SUBMITTED")}
        />
      )}
      {openAdStreamDeliverablesDialog && (
        <AdStreamDeliverablesDialog
          open={openAdStreamDeliverablesDialog}
          handleClose={() => {
            setOpenAdStreamDeliverablesDialog(false);
          }}
          loading={loadingClientDeliveries}
          selectedBillingMonth={selectedBillingMonth}
          setSelectedBillingMonth={setSelectedBillingMonth}
          clientDeliveries={clientDeliveries}
          handleGenerateDeliveryInvoices={handleGenerateDeliveryInvoices}
          isGeneratingInvoices={isGeneratingInvoices}
        />
      )}
      {openConfirmSendEmailToClient && (
        <SendSingleClientMediaInvoiceDialog
          open={openConfirmSendEmailToClient}
          handleClose={handleCloseSendSingleMediaDialog}
          handleSubmit={handleSendMediaInvoice}
          isSendingInvoices={isSendingInvoices}
          company={company}
        />
      )}
     
      <div className="media-campaign-invoice-actions-container">
        <Button
          className={`${selectedItems?.length > 0
            ? "default-button"
            : "default-disabled-button"
            } add-budget-button`}
          size="small"
          onClick={(e) => setOpenConfirmApprove(true)}
          disabled={selectedItems?.length === 0 || downloading}
          variant="contained"
        >
          Approve
        </Button>
        <Button
          className={`${selectedItems?.length > 0
            ? "default-button"
            : "default-disabled-button"
            } add-budget-button`}
          size="small"
          variant="contained"
          onClick={(e) => setOpenConfirmDelete(true)}
          disabled={selectedItems?.length === 0 || downloading}
        >
          Delete
        </Button>
        <Button
          className={`${selectedItems?.length > 0
            ? "default-button"
            : "default-disabled-button"
            } add-budget-button`}
          size="small"
          variant="contained"
          onClick={(e) => setOpenConfirmSendEmailToClient(true)}
          disabled={selectedItems?.length === 0 || downloading}
        >
          Send
        </Button>
        <Button
          className={`${selectedItems?.length > 0
            ? "default-button"
            : "default-disabled-button"
            } add-budget-button`}
          size="small"
          variant="contained"
          onClick={(e) => handleDownloadInvoices()}
          disabled={selectedItems?.length === 0 || downloading}
        >
          Download
        </Button>
        <Autocomplete
          value={
            selectedBillingMonth
              ? {
                display: selectedBillingMonth.format("MMMM YYYY"),
                value: selectedBillingMonth,
              }
              : null
          }
          options={get3monthsBeforeAndAfterCurrentDate()}
          onChange={(event, option) => {
            setSelectedBillingMonth(option.value);
          }}
          getOptionLabel={(option) => option.display}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Billing Month"}
              size="small"
              variant="outlined"
            />
          )}
          disableClearable
        />
        <Button
          className="default-button add-budget-button"
          size="small"
          onClick={(e) => {
            var filteredMediaInvoices = mediaInvoices.filter(m => m.isMediaDelivery != 1);
            if (filteredMediaInvoices.length == 0) {
              setOpenGenerateInvoiceDialog(true)
            } else {
              setOpenCannotGenerateDialog(true);
            }
          }}
        >
          Generate Invoices
        </Button>
        <Button
          className={loadingClientDeliveries ? "default-disabled-button" : "default-button-grey add-budget-button"}
          size="small"
          onClick={(e) => setOpenAdStreamDeliverablesDialog(true)}
          disabled={loadingClientDeliveries}
        >
          {loadingClientDeliveries ? `Fetching Deliveries...` : `Media Deliveries (${clientDeliveries.length})`}
        </Button>
        <div></div>
        <Button
          className="default-button-grey add-budget-button"
          size="small"
          onClick={(e) => setOpenAccountContactDetails(true)}
        >
          Account Contact Details
        </Button>
      </div>
      <div>
        {isLoadingInvoices ? (
          <div style={{ display: "grid" }}>
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
          </div>
        ) : (
          <div className="media-spend-allocation-root">
            {mediaInvoices?.length === 0 ? (
              <div
                className="media-spend-allocation-paper"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 30,
                }}
              >
                <Typography>
                  <strong>No Media Invoices Found</strong>
                </Typography>
              </div>
            ) : (
              <div className="media-spend-allocation-paper">
                <MediaCampaignInvoiceTable
                  mediaInvoices={mediaInvoices}
                  selectAll={selectAll}
                  selectedItems={selectedItems}
                  handleToggleAll={handleToggleAll}
                  handleToggleItem={handleToggleItem}
                  organisationId={organisationId}
                  updateReferenceMediaInvoice={updateReferenceMediaInvoice}
                  setSnackBarProps={setSnackBarProps}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaCampaignInvoice;
