import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@material-ui/core";
import React from "react";
import ReconcileMediaUndersAndOversTableRow from "./ReconcileMediaUndersAndOversTableRow";

const ReconcileMediaUndersAndOversTable = ({
  reconLines,
  handleUpdateSelectedReconLines,
  handleRemoveSelectedReconLine,
  payable,
}) => {
  return (
    <Table className="reconcile-centre-table-root">
      <TableHead>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Client</Typography>
        </TableCell>
        <TableCell
          className="reconcile-table-head-cell-root"
          style={{ width: 180 }}
        >
          <Typography variant="caption">Job Code</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Expected Supplier Bill</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Received Supplier Bill</Typography>
        </TableCell>
        {/* <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Under/Over Spend</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Client Invoice</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Actual Client Cost</Typography>
        </TableCell> */}
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">
            Running Total of Unders/Overs
          </Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Unmatched Remaining</Typography>
        </TableCell>
        <TableCell className="reconcile-table-head-cell-root">
          <Typography variant="caption">Use</Typography>
        </TableCell>
      </TableHead>
      <TableBody>
        {reconLines.map((reconLine, index) => (
          <ReconcileMediaUndersAndOversTableRow
            key={index}
            reconLine={reconLine}
            handleUpdateSelectedReconLines={handleUpdateSelectedReconLines}
            handleRemoveSelectedReconLine={handleRemoveSelectedReconLine}
            payable={payable}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReconcileMediaUndersAndOversTable;
