import React, { useEffect, useState } from "react";
import "./currencyExchangeForm.scss";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import {
  currencyFormat,
  currencyNumberFormat,
  getCurrencies,
} from "../../utils/GeneralUtils";
import CurrencyOptionItem from "./CurrencyOptionItem";

const CurrencyExchangeForm = ({
  currency,
  handleSubmit,
  foreignTotal,
  nzdTotal,
  lockDropdown = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedCurrency, setSelectedCurrency] = useState(
    currency && currency !== "NZD"
      ? getCurrencies(true).find((c) => c.value === currency)
      : getCurrencies(true)[0]
  );
  const [rates, setRates] = useState(null);
  const [marginRate, setMarginRate] = useState(0);
  const [nzdAmount, setNzdAmount] = useState(lockDropdown ? (nzdTotal ? nzdTotal : null) : null);
  const [foreignAmount, setForeignAmount] = useState(
    foreignTotal ? foreignTotal : null
  );

  useEffect(() => {
    searchCurrencyRate();
    if (!lockDropdown) handleCalculateConversion(currency, foreignAmount);
  }, [selectedCurrency, rates, marginRate]);

  const searchCurrencyRate = () => {
    if (rates === null) {
      axios
        .get(`/ExchangeRate/GetLatestRates`)
        .then(({ data }) => {
          setRates(data.rates);
          setMarginRate(data.marginRate);
          if (!lockDropdown) handleCalculateConversion(currency, foreignTotal || 0);
        })
        .catch((err) => alert(err));
    }
  };

  const handleCalculateConversion = (fromCurrency, amount) => {
    if (rates && selectedCurrency) {
      if (fromCurrency === "NZD") {
        setNzdAmount(amount);
        setForeignAmount(
          currencyNumberFormat(
            (rates[selectedCurrency.value] -
              rates[selectedCurrency.value] * marginRate) *
            amount
          )
        );
      } else {
        setForeignAmount(amount);
        setNzdAmount(
          currencyNumberFormat(
            amount /
            (rates[selectedCurrency.value] -
              rates[selectedCurrency.value] * marginRate)
          )
        );
      }
    }
  };

  return (
    <div className="currency-exchange-form-root-container">
      <div className="currency-exchange-form-calculator-wrapper">
        <div className="currency-exchange-form-input-wrapper">
          <Typography variant="caption">Convert</Typography>
          <div className="currency-exchange-form-input-container">
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              style={{ padding: 10 }}
              value={nzdAmount}
              defaultValue={0}
              // onChange={(e) => {
              //   setNzdAmount(e.target.value);
              //   handleCalculateConversion("NZD", e.target.value);
              // }}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^(\d*\.?\d*)?$/.test(inputValue) || inputValue === "") {
                  setNzdAmount(inputValue);
                  handleCalculateConversion("NZD", inputValue);
                }
              }}
            />
            <CurrencyOptionItem
              option={{
                value: "NZD",
                code: "nz",
                symbol: "$",
              }}
            />
          </div>
        </div>
        {!!(rates && selectedCurrency) && (
          <div className="currency-exchange-form-rates-label-wrapper">
            <div className="currency-exchange-form-rates-label-container">
              <Typography variant="caption">$1.00 NZD</Typography>
              <Typography variant="caption">=</Typography>
              <Typography variant="caption">
                {selectedCurrency.symbol +
                  "" +
                  currencyNumberFormat(
                    Number(
                      rates[selectedCurrency.value] -
                      rates[selectedCurrency.value] * marginRate
                    )
                  ) +
                  " " +
                  selectedCurrency.value}
              </Typography>
            </div>
            <div className="currency-exchange-form-rates-label-container">
              <Typography variant="caption">
                {selectedCurrency.symbol +
                  "" +
                  "1.00" +
                  " " +
                  selectedCurrency.value}
              </Typography>
              <Typography variant="caption">=</Typography>
              <Typography variant="caption">
                {"$" +
                  currencyNumberFormat(
                    Number(
                      1 /
                      (rates[selectedCurrency.value] -
                        rates[selectedCurrency.value] * marginRate)
                    )
                  ) +
                  " NZD"}
              </Typography>
            </div>
          </div>
        )}

        <div className="currency-exchange-form-input-wrapper">
          <Typography variant="caption">To</Typography>
          <div className="currency-exchange-form-input-container">
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              style={{ padding: 10 }}
              value={foreignAmount}
              onChange={(e) => {
                // handleCalculateConversion(
                //   selectedCurrency.value,
                //   e.target.value
                // )
                const inputValue = e.target.value;
                if (/^(\d*\.?\d*)?$/.test(inputValue)) {
                  handleCalculateConversion(
                    selectedCurrency.value,
                    e.target.value
                  )
                }
              }
              }
            />
            <div>
              <div
                onClick={(e) => {
                  if (!lockDropdown) {
                    setAnchorEl(e.currentTarget)
                  }
                }
                }
                className={lockDropdown ? "" : "currency-exchange-form-currecy-dropdown"}
              >
                <CurrencyOptionItem option={selectedCurrency} />
                {!lockDropdown && <ExpandMoreIcon />}
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {getCurrencies(true).map((currency, index) => (
                  <MenuItem
                    onClick={(e) => {
                      setSelectedCurrency(currency);
                      setAnchorEl(null);
                    }}
                  >
                    <CurrencyOptionItem option={currency} key={index} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        <div className="currency-exchange-form-flex-end-container">
          <Button
            size="small"
            variant="contained"
            color={"primary"}
            onClick={() =>
              handleSubmit(selectedCurrency.value, foreignAmount, nzdAmount)
            }
          >
            Use this rate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrencyExchangeForm;
