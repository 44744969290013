import { Table, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../../utils/GeneralUtils";

const BudgetTotalTable = ({ budget }) => {
  const getTotalQuotedAmount = (field) => {
    let total = 0;

    for (const item of filterBudget()) {
      if (item[field] && typeof item[field] === "number") {
        total += item[field];
      } else {
        total += Number(item[field]);
      }
    }
    return total;
  };

  const filterBudget = (budgetByProject = null) => {
    return budgetByProject
      ? budgetByProject.filter((campaign) => {
          return campaign.isHidden !== 1;
        })
      : budget.filter((campaign) => {
          return campaign.isHidden !== 1;
        });
  };

  return (
    <Table className="budget-accordion-footer-table">
      <TableRow>
        <TableCell className="column1 no-border-cell no-padding-cell no-background-cell">
          <strong>Total</strong>
        </TableCell>
        <TableCell className="column2 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Quoted</Typography>

            <Typography variant="caption">
              {currencyFormat(getTotalQuotedAmount("quoted"))}
            </Typography>
          </div>
        </TableCell>
        <TableCell className="column3 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Allocated</Typography>

            <Typography variant="caption">
                {currencyFormat(getTotalQuotedAmount("allocated"))}
            </Typography>
          </div>
        </TableCell>
        <TableCell className="column4 no-border-cell no-padding-cell no-background-cell">
          <div className="budget-accordion-header-total-column-container">
            <Typography variant="caption">Remaining</Typography>
            <Typography variant="caption">
                {currencyFormat(getTotalQuotedAmount("remaining"))}
            </Typography>
          </div>
        </TableCell>
        <TableCell className="column5 no-border-cell no-padding-cell no-background-cell"></TableCell>
      </TableRow>
    </Table>
  );
};

export default BudgetTotalTable;
