import React, { useState, useEffect, useRef, Fragment } from "react";
import Axios from "axios";
import { getScheduleItems } from "../../../../actions/scheduleItems";
import { connect } from "react-redux";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  Paper,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  Switch,
  Tooltip,
  RadioGroup,
  Radio,
  FormLabel,
  Fab,
  Select,
  InputLabel,
  requirePropFactory,
  CircularProgress,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SimpleAlerts from "./CompleteInfoNotification";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddAgentPopUp from "../../Schedule/NewContact/AddAgentPopUp";
import TalentAgencyPopUp from "./TalentAgencyPopUp";
import "./ContractorPopUp.css";
import { checkEmail } from "../../../../utils/Validation";
import HistoryTable from "../../Contract/HistoryTable";
import { useLocation } from "react-router-dom";
import { contains } from "../../../../utils/utils";
import { setDialogProps } from "../../../../actions/dialog";
import InformationDialogy from "../../../Shared/InformationDialogy";
import PreimbursePopUp from "../../../Shared/PreimbursePopUp";
import Snacky from "../../../Shared/Snacky";
import NoBankAccountWarning from "../../../Admin/Reimbursement/NoBankAccountWarning";
import UpdateInfluencerForm from "../../../InfluencersNetwork/UpdateInfluencerForm";
import GeneralDocumentViewer from "../../../Shared/Dialog/GeneralDocumentViewer";
import ConfirmationDialogActions from "../../../Shared/ConfirmationDialogActions";
import { checkIfFinancialDetailsHaveChanged } from "./contractorPopupHelpers";
import CurrencyDropdownMenu from "../../../CurrencyExchange/CurrencyDropdownMenu";
import { getCurrencies } from "../../../../utils/GeneralUtils";
import FinancialTab from "./PersonPopUp/FinancialTab";
import { useUserContext } from "../../../../context/user/UserContext";
import PersonRatings from "../../../Shared/Person/PersonRatings";
import GeneralTab from "./PersonPopUp/General/GeneralTab";
import PersonNotes from "../../../Shared/Person/PersonNotes";
import { useAuth } from "../../../../context/auth/AuthContext";

//---------Styling -----------------
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
    height: "610px",
    width: "1000px",
    overflowY: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tabIndicator: {
    backgroundColor: "white",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`contractor-tabpanel-${index}`}
      aria-labelledby={`contractor-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `contractor-tab-${index}`,
    "aria-controls": `contractor-tabpanel-${index}`,
  };
}

const agentInitialState = {
  id: null,
  companyFk: null,
  companyFkNavigation: null,
  firstName: null,
  lastName: null,
  emailAddress: null,
  phoneNumber: null,
  enableSms: null,
};

const ContractorPopUp = (props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [requestInfoStatus, setRequestInfoStatus] = useState("Send");
  const [tabValue, setTabValue] = useState(0);
  const [general, setGeneral] = useState({
    firstName: null,
    lastName: null,
    parentName: null,
    hasParent: null,
    jobTitle: null,
    phoneNumber: null,
    emailAddress: null,
    mealPreference: " ",
    enableSms: null,
  });
  const {
    firstName,
    lastName,
    parentName,
    hasParent,
    jobTitle,
    phoneNumber,
    emailAddress,
    mealPreference,
    enableSms,
  } = general;
  const [financial, setFinancial] = useState({
    currency: "NZD",
    ird: null,
    bankAccount: null,
    rateHour: null,
    rateHalfDay: null,
    rateDay: null,
    rateWeek: null,
    gstRegistered: null,
    wtrate: null,
    bcname: null,
    bcbankAccount: null,
    bcird: null,
    bcisGstreg: null,
    bcwtr: null,
    bcisWt: null,
    bcorIc: null,
    wtexemptionCertificate: null,
  });
  const {
    ird,
    bankAccount,
    rateHour,
    rateHalfDay,
    rateDay,
    rateWeek,
    gstRegistered,
    wtrate,
    bcname,
    bcbankAccount,
    bcird,
    bcisGstreg,
    bcwtr,
    bcisWt,
    bcorIc,
    wtexemptionCertificate,
    ir330c,
  } = financial;

  const [originalIr330cInfo, setOriginalIr330cInfo] = useState({
    ir330cFirstName: null,
    ir330cLastName: null,
    ir330cird: null,
    ir330cBankAccount: null,
    ir330cGstRegistered: null,
    ir330cWtrate: null,
  });
  const {
    ir330cFirstName,
    ir330cLastName,
    iir330cirdrd,
    ir330cBankAccount,
    ir330cGstRegistered,
    ir330cWtrate,
  } = originalIr330cInfo;

  const [originalFinancialInfo, setOriginalFinancialInfo] = useState({
    ird: null,
    bankAccount: null,
    rateHour: null,
    rateHalfDay: null,
    rateDay: null,
    rateWeek: null,
    gstRegistered: null,
    wtrate: null,
    bcname: null,
    bcbankAccount: null,
    bcird: null,
    bcisGstreg: null,
    bcwtr: null,
    bcisWt: null,
    bcorIc: null,
    wtexemptionCertificate: null,
    ir330c: null,
  });
  const {
    originalIrd,
    originalBankAccount,
    originalRateHour,
    originalRateHalfDay,
    originalRateDay,
    originalRateWeek,
    originalGstRegistered,
    originalWtrate,
    originalBcname,
    originalBcbankAccount,
    originalBcird,
    originalBcisGstreg,
    originalBcwtr,
    originalBcisWt,
    originalBcorIc,
    originalWtexemptionCertificate,
    originalIr330c,
  } = originalFinancialInfo;

  const [person, setPerson] = useState(null);
  const [getPerson, setGetPerson] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  //Talent
  const [company, setCompany] = useState(null);

  const [getAgents, setGetAgents] = useState(true);
  const [agentList, setAgentList] = useState([]);
  const [agent, setAgent] = useState(agentInitialState);
  const [agentFirstName, setAgentFirstName] = useState(" ");
  const [agentLastName, setAgentLastName] = useState(" ");
  const [agentPhoneNumber, setAgentPhoneNumber] = useState(" ");
  const [agentEmailAddress, setAgentEmailAddress] = useState(" ");
  const [agentEnableSms, setAgentEnableSms] = useState(null);
  const [agentFk, setAgentFk] = useState(null);
  const [financeOption, setFinanceOption] = useState({
    bcIcOrAgent: null,
  });
  const { bcIcOrAgent } = financeOption;

  const [showAddAgentContact, setShowAddAgentContact] = useState(false);
  const [showTalentAgencyPopUp, setShowTalentAgencyPopUp] = useState(false);
  const [contractList, setContractList] = useState();
  const [fetchContractList, setFetchContractList] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const [providerType, setProviderType] = useState("");
  const [showReimbursmentWarning, setShowReimbursmentWarning] = useState(false);
  const [gettingReimbursmentInfo, setGettingReimbursmentInfo] = useState(false);

  const [socialMediaProfiles, setSocialMediaProfiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmSaveDialog, setShowConfirmSaveDialog] = useState(false);
  const [hasFinancialChanges, setHasFinancialChanges] = useState(false);
  const [isLoadingPersonDetails, setIsLoadingPersonDetails] = useState(false);

  let location = useLocation();

  useEffect(() => {
    //here for reimbursment
    setProviderType(props.providerType);
  }, []);

  // -----Functions-----------
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSave = () => {
    setIsEditing(false);
    setIsSaving(true);
    if (
      providerType === "Talent" ||
      providerType === "Influencer" ||
      providerType === "Content Creator" ||
      providerType === "Contractor"
    ) {
      if (bcIcOrAgent === "a" && agent !== null) {
        //Save Agent Details
        Axios.put(`Agents/UpdateAgent/${agent.id}`, {
          id: agent.id,
          firstName: agentFirstName,
          lastName: agentLastName,
          emailAddress: agentEmailAddress,
          phoneNumber: agentPhoneNumber,
          enableSms: agentEnableSms,
        })
          .then((res) => {
            console.log(res);
            setGetAgents(true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    var gstReg, companyGstReg;
    if (gstRegistered === false) {
      gstReg = 0;
    } else {
      gstReg = 1;
    }
    if (bcisGstreg === false) {
      companyGstReg = 0;
    } else {
      companyGstReg = 1;
    }
    console.log("!!!!!!!!");
    console.log(gstReg);
    Axios.put(
      `People/UpdateContractor/${props.personId}?user=${user?.email}`,
      {
        id: props.personId,
        firstName: firstName,
        lastName: lastName,
        fullName: firstName + " " + lastName,
        hasParent: hasParent,
        parentName: parentName,
        mealPreference: mealPreference === " " ? null : mealPreference,
        enableSms: enableSms,
        jobTitle: jobTitle,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        ird: ird,
        bankAccount: bankAccount,
        rateHour: rateHour,
        rateHalfDay: rateHalfDay,
        rateDay: rateDay,
        rateWeek: rateWeek,
        gstRegistered: gstReg,
        wtrate: wtrate,
        bcname: bcname,
        bcbankAccount: bcbankAccount,
        bcird: bcird,
        bcisGstreg: companyGstReg,
        bcwtr: bcwtr,
        bcisWt: bcisWt,
        bcorIc:
          providerType === "Talent" ||
            providerType === "Influencer" ||
            providerType === "Content Creator" ||
            providerType === "Contractor"
            ? bcIcOrAgent
            : bcorIc,
        agentFk:
          (providerType === "Talent" ||
            providerType === "Influencer" ||
            providerType === "Content Creator" ||
            providerType === "Contractor") &&
            bcIcOrAgent === "a"
            ? agentFk
            : null,
        socialMediaProfile: socialMediaProfiles,
        currency: financial.currency,
      }
    )
      .then(({ data }) => {
        console.log("saved person:", data);
        updateIR330C();
        console.log("TYPE");
        console.log(props.type);
        var newGstReg;
        if (data.gstRegistered === 0) {
          newGstReg = false;
        } else {
          newGstReg = true;
        }
        setOriginalFinancialInfo({
          originalIrd: data.ird,
          originalBankAccount: data.bankAccount,
          originalRateHour: data.rateHour,
          originalRateHalfDay: data.rateHalfDay,
          originalRateDay: data.rateDay,
          originalRateWeek: data.rateWeek,
          originalGstRegistered: newGstReg,
          originalWtrate: data.wtrate,
          originalBcname: data.bcname,
          originalBcbankAccount: data.bcbankAccount,
          originalBcird: data.bcird,
          originalBcisGstreg: data.bcisGstreg,
          originalBwtr: data.bcwtr,
          originalBcisWt: data.bcisWt,
          originalBcorIc: data.bcorIc,
          originalWtexemptionCertificate: data.wtexemptionCertificate,
          originalIr330c: data.ir330c,
        });
        switch (props.type) {
          case "NewScheduleItem":
            setGetPerson(true);
            props.reloadPersonList();
            break;
          case "EditScheduleItem":
            Axios.put(
              `ScheduleItems/UpdateProviderName/${props.scheduleItemId}`,
              {
                id: props.scheduleItemId,
                providerName: firstName + " " + lastName,
              }
            )
              .then((res) => {
                props.getScheduleItems(location.pathname.split("/")[5]);
                setGetPerson(true);
              })
              .catch((e) => {
                props.getScheduleItems(location.pathname.split("/")[5]);
                setGetPerson(true);
              });
            break;
          case "ContactCenterTable":
            props.rowUpdated();
            var newPerson = props.selectedContact;
            newPerson["firstName"] = firstName;
            newPerson["lastName"] = lastName;
            newPerson["emailAddress"] = emailAddress;
            newPerson["phoneNumber"] = phoneNumber;
            newPerson["enableSms"] = enableSms;
            props.handleEditPerson(newPerson);
            break;
          case "CheckInTable":
            var newCheckInItem = props.selectedCheckInItem;
            newCheckInItem["firstName"] = firstName;
            newCheckInItem["lastName"] = lastName;
            newCheckInItem["name"] = `${firstName} ${lastName}`;
            props.handleEditCheckInItem(newCheckInItem);
            break;
          case "Influencer":
            console.log("Influencer handleEdit");
            props.handleEditPerson(data);
            break;
          default:
            setGetPerson(true);
            props.reloadPersonList();
            break;
        }
        // setGetPerson(true);
        // props.reloadPersonList();
      })
      .catch((error) => {
        console.log(error);
        props.reloadScheduleItemsList();
      })
      .finally(() => {
        setIsSaving(false);
        setShowConfirmSaveDialog(false);
      });
  };

  const updateIR330C = () => {
    if (
      originalIr330cInfo.ir330cFirstName !== firstName ||
      originalIr330cInfo.ir330cLastName !== lastName ||
      originalIr330cInfo.ir330cWtrate !== wtrate ||
      originalIr330cInfo.ir330cird !== ird ||
      originalIr330cInfo.ir330cBankAccount !== bankAccount
    ) {
      //Delete IR330C
      Axios.delete(
        `People/DeletePersonIR330C/${props.personId}?user=${user?.email}`
      )
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleBasicFinancialInfoChange = (key) => (event) => {
    console.log(key === "wtrate");
    if (key === "wtrate") {
      var regex = /^(\(?\+?[0-9]*\)?)?[0-9_\-\(\)]*$/;
      if (event.target.value === "" || event.target.value === null) {
        setFinancial({ ...financial, [key]: null });
      } else if (regex.test(event.target.value)) {
        setFinancial({ ...financial, [key]: parseInt(event.target.value) });
      }
    } else {
      setFinancial({ ...financial, [key]: event.target.value });
    }
  };

  //-----Use Effects--------------
  useEffect(() => {
    if (getPerson) {
      setGetPerson(false);
      setIsLoadingPersonDetails(true);
      Axios.get(`People/GetPersonWithAgentCompany/${props.personId}`)
        .then((res) => {
          console.log(res.data);
          setPerson(res.data);
          setSocialMediaProfiles(res.data.socialMediaProfile);
          setGettingReimbursmentInfo(res.data.updateToken != null);
          setGeneral({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            parentName: res.data.parentName,
            hasParent: res.data.hasParent,
            jobTitle: res.data.jobTitle,
            phoneNumber: res.data.phoneNumber,
            emailAddress: res.data.emailAddress,
            mealPreference: res.data.mealPreference,
            enableSms: res.data.enableSms,
          });
          var gstReg;
          if (res.data.gstRegistered === 0) {
            gstReg = false;
          } else {
            gstReg = true;
          }
          setFinancial({
            currency: res.data.currency,
            ird: res.data.ird,
            bankAccount: res.data.bankAccount,
            rateHour: res.data.rateHour,
            rateHalfDay: res.data.rateHalfDay,
            rateDay: res.data.rateDay,
            rateWeek: res.data.rateWeek,
            gstRegistered: gstReg,
            wtrate: res.data.wtrate,
            bcname: res.data.bcname,
            bcbankAccount: res.data.bcbankAccount,
            bcird: res.data.bcird,
            bcisGstreg: res.data.bcisGstreg,
            bcwtr: res.data.bcwtr,
            bcisWt: res.data.bcisWt,
            bcorIc: res.data.bcorIc,
            bankAccountNavigation: res.data.bankAccountNavigation,
            wtexemptionCertificate: res.data.wtexemptionCertificate,
            ir330c: res.data.ir330c,
          });
          setOriginalFinancialInfo({
            originalIrd: res.data.ird,
            originalBankAccount: res.data.bankAccount,
            originalRateHour: res.data.rateHour,
            originalRateHalfDay: res.data.rateHalfDay,
            originalRateDay: res.data.rateDay,
            originalRateWeek: res.data.rateWeek,
            originalGstRegistered: gstReg,
            originalWtrate: res.data.wtrate,
            originalBcname: res.data.bcname,
            originalBcbankAccount: res.data.bcbankAccount,
            originalBcird: res.data.bcird,
            originalBcisGstreg: res.data.bcisGstreg,
            originalBwtr: res.data.bcwtr,
            originalBcisWt: res.data.bcisWt,
            originalBcorIc: res.data.bcorIc,
            originalWtexemptionCertificate: res.data.wtexemptionCertificate,
            originalIr330c: res.data.ir330c,
          });
          setRequestInfoStatus(
            res.data.requestInfoStatus === "Pending" ? "Pending" : "Send"
          );
          setOriginalIr330cInfo({
            ir330cFirstName: res.data.firstName,
            ir330cLastName: res.data.lastName,
            ir330cird: res.data.ird,
            ir330cBankAccount: res.data.bankAccount,
            ir330cGstRegistered: res.data.bcisGstreg,
            ir330cWtrate: res.data.wtrate,
          });
          if (
            providerType === null ||
            providerType === undefined ||
            providerType === ""
          ) {
            setProviderType(res.data.personCategory);
          }
          try {
            setAgent(res.data.agentFkNavigation);
            setCompany(res.data.agentFkNavigation.companyFkNavigation);
          } catch (e) {
            console.log("No Agent");
          }
          setFinanceOption({
            bcIcOrAgent:
              res.data.agentFkNavigation !== null ? "a" : res.data.bcorIc,
          });
        })
        .catch((e) => {
          console.log("Contractor Popup");
          console.log(e);
        })
        .finally(() => {
          setIsLoadingPersonDetails(false);
        });
    }
  });

  useEffect(() => {
    if (
      getAgents &&
      (providerType === "Talent" ||
        providerType === "Influencer" ||
        providerType === "Content Creator" ||
        providerType === "Contractor")
    ) {
      setGetAgents(false);
      Axios.get(`Agents/GetAgents`, {
        params: { organisationId: (providerType === "Influencer" || providerType === "Content Creator") ? 2 : 1 },
      })
        .then((res) => {
          console.log(res.data);
          setAgentList(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  useEffect(() => {
    if (agent !== null) {
      setAgentFirstName(agent.firstName);
      setAgentLastName(agent.lastName);
      setAgentEmailAddress(agent.emailAddress);
      setAgentPhoneNumber(agent.phoneNumber);
      setAgentEnableSms(agent.enableSms);
      setAgentFk(agent.id);
    }
    if (agent !== null && agent.companyFkNavigation !== null) {
      setCompany(agent.companyFkNavigation);
    }
  }, [agent]);

  useEffect(() => {
    if (fetchContractList) {
      Axios.get(`People/GetHistory/${props.personId}`)
        .then((res) => {
          console.log("GetHistory");
          console.log(res);
          var contracts = res.data;
          contracts.map((c) => {
            if (c.dateSigned !== null) {
              c.dateSigned = new Date(c.dateSigned);
            }
          });
          setContractList(contracts);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setFetchContractList(false);
        });
    }
  }, [fetchContractList]);

  //-----End of Use Effects-----------

  const getContracts = (contractDisplayViewModel, type) => {
    var contracts = [];
    for (var i = 0; i < contractDisplayViewModel.length; i++) {
      if (type === "Contractor") {
        for (
          var j = 0;
          j < contractDisplayViewModel[i].contractorContract.length;
          j++
        ) {
          contracts.push({
            date:
              contractDisplayViewModel[i].contractorContract[j].dateSigned !==
                null
                ? new Date(
                  contractDisplayViewModel[i].contractorContract[j].dateSigned
                )
                : null,
            projectName: contractDisplayViewModel[i].projectName,
            projectTag: contractDisplayViewModel[i].projectTag,
            scheduleName: contractDisplayViewModel[i].scheduleName,
            contract:
              contractDisplayViewModel[i].contractorContract[j].driveFileId,
            status:
              contractDisplayViewModel[i].contractorContract[j].status ===
                "Sent"
                ? "Calendar Sent"
                : contractDisplayViewModel[i].contractorContract[j].status,
            projectId: contractDisplayViewModel[i].projectId,
            scheduleId: contractDisplayViewModel[i].scheduleId,
            healthAndHazardsFileId:
              contractDisplayViewModel[i].healthAndHazardsFileId,
            talentReleaseFileId: null,
          });
        }
      }
      if (type === "Talent" || type === "Influencer" || type === "Content Creator") {
        for (
          var j = 0;
          j < contractDisplayViewModel[i].talentContract.length;
          j++
        ) {
          contracts.push({
            date:
              contractDisplayViewModel[i].talentContract[j].dateSigned !== null
                ? new Date(
                  contractDisplayViewModel[i].talentContract[j].dateSigned
                )
                : null,
            projectName: contractDisplayViewModel[i].projectName,
            projectTag: contractDisplayViewModel[i].projectTag,
            scheduleName: contractDisplayViewModel[i].scheduleName,
            contract: contractDisplayViewModel[i].talentContract[j].driveFileId,
            status:
              contractDisplayViewModel[i].talentContract[j].status === "Sent"
                ? "Calendar Sent"
                : contractDisplayViewModel[i].talentContract[j].status,
            projectId: contractDisplayViewModel[i].projectId,
            scheduleId: contractDisplayViewModel[i].scheduleId,
            healthAndHazardsFileId:
              contractDisplayViewModel[i].healthAndHazardsFileId,
            talentReleaseFileId:
              contractDisplayViewModel[i].talentReleaseFileId,
          });
        }
      }
    }

    return contracts.sort(function (a, b) {
      var dateA = a.date,
        dateB = b.date;
      return dateB - dateA;
    });
    //return contracts.sort();
  };

  const handleResendTypeform = () => {
    Axios.post(`Mail/ResendTypeForm/${props.personId}`)
      .then((res) => {
        console.log(res.data);
        setRequestInfoStatus("Pending");
        Axios.put(`People/UpdateRequestInfoStatus/${props.personId}`, {
          id: props.personId,
          requestInfoStatus: "Pending",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleUpdateAgent = (selectedAgent) => {
    if (selectedAgent) {
      Axios.put(`Agents/UpdateAgent/${selectedAgent.id}`, selectedAgent)
        .then(({ data }) => {
          setAgent(data);
          Axios.put(
            `people/UpdatePersonsAgent/${props.personId}/${selectedAgent.id}`
          )
            .then((res) => {
              setAgentFk(res.data.id);
            })
            .catch((err) => alert("Unable to update person's agent"));
        })
        .catch((err) => alert("Unable to update agent details"));
    }
  };

  const handleSaveBankAccountDetails = (currency, bankAccountDetails) => {
    Axios.put(
      `People/UpdateContractorBankAccountDetails/${props.personId}/${currency}?user=${user?.email}`,
      bankAccountDetails
    )
      .then(({ data }) => {
        setFinancial({
          ...financial,
          currency: currency,
          bankAccountNavigation: bankAccountDetails,
        });
        setAgent(agentInitialState);
        setAgentFk(null);
        setHasFinancialChanges(false);
      })
      .catch((err) => alert("Unable to update financial details"));
  };

  const financialTab = () => {
    console.log("financialTab");
    console.log(providerType);
    if (
      providerType === "Location Contact" ||
      providerType === "Client" ||
      providerType === "Staff"
    ) {
      return (
        // <LocationContactInfo personId={props.personId} isEditing={isEditing} />
        <FormControl
          component="fieldset"
          className={classes.formControl}
          style={{
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                key={"ICBankAccount"}
                label="Bank Account"
                value={bankAccount}
                defaultValue={" "}
                disabled={!isEditing}
                multiline={true}
                variant="outlined"
                style={{ width: "100%" }}
                onChange={handleBasicFinancialInfoChange("bankAccount")}
              />
            </Grid>
          </Grid>
        </FormControl>
      );
    } else {
      return (
        <div>
          {console.log("providerType", providerType)}
          {person &&
            (providerType === "Talent" ||
              providerType === "Influencer" ||
              providerType === "Content Creator" ||
              providerType === "Contractor") && (
              <FinancialTab
                person={person}
                handleSaveBankAccountDetails={handleSaveBankAccountDetails}
                setHasFinancialChanges={setHasFinancialChanges}
                setShowAddAgentContact={setShowAddAgentContact}
                handleUpdateAgent={handleUpdateAgent}
                showTalentAgencyPopUp={showTalentAgencyPopUp}
                setShowTalentAgencyPopUp={setShowTalentAgencyPopUp}
                showAddAgentContact={showAddAgentContact}
              />
            )}
        </div>
      );
    }
  };

  const [preimburseModal, setPreimburseModal] = useState(false);
  const handlePreimburseModal = (value) => {
    setPreimburseModal(value);
  };
  const [showSnackbar, setshowSnackbar] = useState({
    open: false,
    state: null,
  });

  const handleSnacky = (value, state) => {
    setshowSnackbar({
      open: value,
      state: state,
    });
  };

  const handleUpdatePersonGeneralState = (personDto) => {
    setPerson({
      ...person,
      firstName: personDto.firstName,
      lastName: personDto.lastName,
      phoneNumber: personDto.phoneNumber,
      emailAddress: personDto.emailAddress,
      mealPreference: personDto.mealPreference,
      enableSms: personDto.enableSms,
      parentName: personDto.parentName,
      hasPets: personDto.hasPets,
      hasChildren: personDto.hasChildren,
      mainIndustryId: personDto.mainIndustryId,
      ageCategoryId: personDto.ageCategoryId,
    });
  };

  const handleSaveSocialMediaProfiles = () => {
    Axios.post(`Influencer/ReplaceSocialMediaProfiles/${props.personId}`, socialMediaProfiles)
    .then(res => {
      setSocialMediaProfiles(res.data);
      var newPerson = {...person, socialMediaProfiles: res.data}
      props.handleEditPerson(newPerson);
    }).catch(e => {
      console.log(e)
    })
  }

  return (
    <Modal
      aria-labelledby="contractor-modal"
      aria-describedby="contractor-modal"
      open={props.modalState}
      onClose={props.handleModal}
      // className={classes.modal}
      className={
        showAddAgentContact || showTalentAgencyPopUp
          ? "contractorPopUpModalLeftAligned"
          : "contractorPopUpModal"
      }
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div>
        <Snacky
          snackprops={
            showSnackbar.state === "Success"
              ? {
                open: showSnackbar.open,
                severity: "success",
                text: "Preimbursement sent",
              }
              : {
                open: showSnackbar.open,
                severity: "error",
                text: "Preimbursement failed",
              }
          }
          setSnackBarProps={() => handleSnacky(false)}
        />
        {showConfirmSaveDialog && (
          <ConfirmationDialogActions
            title={"Confirm Save"}
            open={showConfirmSaveDialog}
            contentText={
              "We have detected financial changes. Are you sure you want to save these changes?"
            }
            button1Action={() => setShowConfirmSaveDialog(false)}
            button1Text={"Cancel"}
            button2Text={"Save Changes"}
            button2Action={handleSave}
            isLoading={isSaving}
          />
        )}
        <NoBankAccountWarning
          open={showReimbursmentWarning}
          handleClose={() => setShowReimbursmentWarning(false)}
          personId={props.personId}
          setGettingReimbursmentInfo={setGettingReimbursmentInfo}
        />
        {props.modalState && (
          <PreimbursePopUp
            handleSnacky={handleSnacky}
            preimburseModal={preimburseModal}
            handlePreimburseModal={handlePreimburseModal}
            personId={props.personId}
          />
        )}
        {showWarning ? (
          <InformationDialogy
            setDialogProps={setDialogProps}
            buttonProps={{
              show: showWarning,
              titleText: "Invalid data",
              contentText: `The email under the "General" Tab is not filled in correctly. Please fill this in to continue.`,
              buttonColor: "primary",
              buttonText: "Ok",
            }}
            handleButton={() => setShowWarning(false)}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <AppBar position="static" style={{ background: "#000000" }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              {providerType} Info
            </Typography>
            <IconButton
              onClick={props.handleModal}
              color="inherit"
              style={{ color: "white" }}
              disabled={isSaving}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {props.type === "NewScheduleItem" && (
              <div style={{ marginRight: 5 }}>
                <Button
                  variant="contained"
                  className="SelectExisting"
                  onClick={() => {
                    props.closePopUps();
                    props.selectExisting({
                      id: props.personId,
                      firstName:
                        firstName.charAt(0).toUpperCase() + firstName.slice(1),
                      lastName:
                        lastName.charAt(0).toUpperCase() + lastName.slice(1),
                    });
                  }}
                >
                  Select Existing
                </Button>
              </div>
            )}
          </div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              classes={{
                indicator: classes.tabIndicator,
              }}
            >
              <Tab label="General" {...a11yProps(0)} />
              {providerType === "Talent" ||
                providerType === "Contractor" ||
                providerType === "Location Contact" ||
                providerType === "Influencer" ||
                providerType === "Content Creator" ||
                providerType === "Client" ||
                providerType === "Staff" ? (
                bcorIc ||
                  agentFk ||
                  ((providerType === "Location Contact" ||
                    providerType === "Client" ||
                    providerType === "Staff") &&
                    bankAccount) ? (
                  <Tab label="Financial" {...a11yProps(1)} />
                ) : (
                  <HtmlTooltip
                    title={"Please click to enter details"}
                    interactive
                    arrow
                    placement="top"
                  >
                    <Tab
                      label={
                        <div>
                          <NotificationImportantIcon
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                          Financial
                        </div>
                      }
                      {...a11yProps(1)}
                      style={{ background: "#ff0059" }}
                    />
                  </HtmlTooltip>
                )
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <Tab label="History" {...a11yProps(2)} />
              <Tab label="Social Media" {...a11yProps(3)} />
              <Tab label="Reviews" {...a11yProps(4)} />
              <Tab label="Notes" {...a11yProps(5)} />
            </Tabs>
          </AppBar>
          {/* General Information Panel */}
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              {!gettingReimbursmentInfo ? (
                <React.Fragment>
                  <Grid item xs={6} md={6} lg={6}>
                    {(bcorIc != null ||
                      agentFk != null ||
                      ((providerType === "Location Contact" ||
                        providerType === "Client" ||
                        providerType === "Staff") &&
                        bankAccount)) && (
                        <div>
                          <Button
                            className={
                              gettingReimbursmentInfo
                                ? "default-button-grey"
                                : "default-button"
                            }
                            disabled={gettingReimbursmentInfo}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              if (!gettingReimbursmentInfo) {
                                if (
                                  (bcIcOrAgent == "ic" &&
                                    (bankAccount == "" || bankAccount == null)) ||
                                  (bcIcOrAgent == "bc" &&
                                    (bcbankAccount == "" ||
                                      bcbankAccount == null))
                                ) {
                                  setShowReimbursmentWarning(true);
                                } else {
                                  window.open(
                                    `https://pay.thompsonspencer.com/home/${props.personId}`
                                  );
                                  //window.open(`https://localhost:44307/reimburse/${props.personId}`)
                                }
                              }
                            }}
                          >
                            Reimburse
                          </Button>
                          <Button
                            className={
                              gettingReimbursmentInfo
                                ? "default-button-grey"
                                : "default-button"
                            }
                            disabled={gettingReimbursmentInfo}
                            color="primary"
                            variant="contained"
                            size="small"
                            style={{ marginLeft: "15px" }}
                            onClick={() => {
                              if (!gettingReimbursmentInfo) {
                                if (bankAccount == "" || bankAccount == null) {
                                  setShowReimbursmentWarning(true);
                                } else {
                                  handlePreimburseModal(true);
                                }
                              }
                            }}
                          >
                            Preimburse
                          </Button>
                        </div>
                      )}
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}></Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      disabled={true}
                      style={{
                        backgroundColor: "rgb(255, 165, 0)",
                        color: "white",
                      }}
                    >
                      Reimbursment details requested
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <GeneralTab
              handleUpdatePersonGeneralState={handleUpdatePersonGeneralState}
              person={person}
              isLoadingPersonDetails={isLoadingPersonDetails}
            />
          </TabPanel>
          {/* Financial Information Panel */}
          <TabPanel value={tabValue} index={1}>
            {financialTab()}
          </TabPanel>
          {/* Contract History Panel */}
          <TabPanel value={tabValue} index={2}>
            <HistoryTable
              contractList={contractList}
              providerType={providerType}
            />
          </TabPanel>
          {/* Social Media Panel */}
          <TabPanel value={tabValue} index={3}>
            <UpdateInfluencerForm
              insideEditModal={true}
              isEditing={true}
              setSocialMediaProfiles={setSocialMediaProfiles}
              selectedInfluencer={{
                socialMediaProfile: socialMediaProfiles,
              }}
              handleCancel={() => { }}
              handleSubmit={handleSaveSocialMediaProfiles}
              personCategory={providerType}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <PersonRatings personId={props.personId} />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <PersonNotes personId={props.personId} />
          </TabPanel>
          {tabValue !== 0 &&
            tabValue !== 1 &&
            tabValue !== 2 &&
            tabValue !== 3 &&
            tabValue !== 4 &&
            tabValue !== 5 && (
              <div className="action-button-container">
                {isSaving ? (
                  <CircularProgress />
                ) : (
                  <Button
                    className={
                      isSaving ? "default-button-grey" : "default-button"
                    }
                    variant="contained"
                    size="small"
                    disabled={isSaving}
                    onClick={() => {
                      if (
                        checkIfFinancialDetailsHaveChanged(
                          originalFinancialInfo,
                          financial
                        )
                      ) {
                        setShowConfirmSaveDialog(true);
                      } else {
                        handleSave();
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  getScheduleItems,
  setDialogProps,
})(ContractorPopUp);
