import React, { useState, useEffect } from "react";
import axios from "axios";
import { Checkbox, TextField, Typography, TableCell, Table, TableHead, TableRow, TableBody, Tooltip, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { filterNumbersOnlyInput } from "../../../../utils/GeneralUtils";

const ReconcileReceiptNoProject = ({
    payable,
    handleUpdateSelectedReconLines,
    handleRemoveSelectedReconLine
}) => {

    const [expenseCodes, setExpenseCodes] = useState([]);
    const [usageType, setUsageType] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(payable.total);
    const [selectedExpenseCode, setSelectedExpenseCode] = useState(null);

    const getExpenseCodes = () => {
        axios.get(`ExpenseCodes/SearchExpenseCodes`, {
            params: {
                organisationId: -1
            }
        }).then(res => {
            setExpenseCodes(res.data);
        })
    }

    const handleUpdateAmount = (amount) => {
        setSelectedAmount(amount);
        handleUpdateSelectedReconLines(
            0,
            amount,
            selectedExpenseCode?.accountCode
        );
    };

    const handleChangeUsePartialCheckbox = (isChecked) => {
        if (isChecked) {
            handleUpdateSelectedReconLines(
                0,
                selectedAmount,
                selectedExpenseCode?.accountCode
            );
            setUsageType("PARTIAL")
        } else {
            handleRemoveSelectedReconLine(0);
            setUsageType(null)
        }
    };

    useEffect(() => {
        getExpenseCodes();
    }, [])

    useEffect(() => {
        if (selectedExpenseCode === null) {
            handleChangeUsePartialCheckbox(false)
        }
    }, [selectedExpenseCode])

    useEffect(() => {
        setSelectedAmount(payable.subtotal)
    }, [payable.subtotal])

    return (
        <div >
            {/* className="payable-reconcile-receipt-no-project-container" */}
            <Typography variant="h5" className="header">Non Project Expenses</Typography>
            <Table className="reconcile-centre-table-root" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6} className="reconcile-table-head-cell-root">
                            <Typography variant="caption" className="sub-header">Xero Account Code</Typography>
                        </TableCell>
                        <TableCell className="reconcile-table-head-cell-root" style={{ width: 70 }}></TableCell>
                        <TableCell className="reconcile-table-head-cell-root" style={{ width: 120 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} className="reconcile-table-cell-root">
                            <Autocomplete
                                options={expenseCodes}
                                value={selectedExpenseCode}
                                getOptionLabel={(option) => option.id == null ? `` : `${option.accountCode} - ${option.accountName}`}
                                onChange={(e, newValue) => {
                                    setSelectedExpenseCode(newValue)
                                    if(usageType !== "PARTIAL"){
                                        setUsageType("PARTIAL")
                                        handleUpdateSelectedReconLines(
                                            0,
                                            selectedAmount,
                                            newValue?.accountCode
                                        );
                                    }
                                }}
                                selectOnFocus
                                renderOption={(option) => {
                                    return <li key={option.id}>{`${option.accountCode} - ${option.accountName}`}</li>;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Account code"
                                        //variant="filled"
                                        size="small"
                                        fullWidth={true}
                                        helperText={selectedExpenseCode?.accountCode == 357 ? "Note: This will use NZD Gst Inc on the receipt and split it in half with Entertainment Non Deductable" : ""}
                                    />
                                )}
                                size="small"
                            />
                        </TableCell>
                        <TableCell colSpan={1} className="reconcile-table-cell-root">
                            <Tooltip title={selectedExpenseCode == null ?
                                <React.Fragment>
                                    <Typography >No account code</Typography>
                                    <Typography variant="caption">Please select an account code first</Typography>
                                </React.Fragment>
                                : ""}
                                arrow={true}
                            >
                                <div className="reconcile-table-single-checkbox-container">
                                    <Checkbox
                                        checked={usageType === "PARTIAL"}
                                        onChange={(e) => handleChangeUsePartialCheckbox(e.target.checked)}
                                        disabled={selectedExpenseCode == null}
                                    />
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell colSpan={1} className="reconcile-table-cell-root">
                            <TextField
                                fullWidth
                                value={selectedAmount}
                                onChange={(e) => handleUpdateAmount(filterNumbersOnlyInput(e.target.value))}
                                size="small"
                                disabled={usageType !== "PARTIAL"}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    )
}

export default ReconcileReceiptNoProject;