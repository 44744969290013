import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import {
  Edit as EditIcon
} from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GeneralDocumentViewer from "../../../Shared/Dialog/GeneralDocumentViewer";
import EditInvoiceReferenceModal from "./EditInvoiceReferenceModal";
import { currencyFormat } from "../../../../utils/GeneralUtils";
import Axios from "axios";

const MediaCampaignInvoiceTable = ({ mediaInvoices, handleToggleItem, handleToggleAll, selectedItems,
  selectAll, organisationId, updateReferenceMediaInvoice, setSnackBarProps }) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(false);
  const [showReferenceDialog, setShowReferenceDialog] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleOpenPdf = (e, xeroInvoiceId) => {
    setPdfUrl(`/invoice/GetXeroInvoiceAsPdf/${xeroInvoiceId}/${organisationId}`);
    setShowDocumentViewer(true);
  };

  const updateInvoice = (invoiceId, reference) => {
    try {
      if (invoiceId) {
        setSaving(true);
        Axios.post(`Invoice/UpdateInvoiceReference/${reference || "-"}/${invoiceId}/${organisationId}`)
          .then(res => {
            updateReferenceMediaInvoice(invoiceId, reference)
            setSnackBarProps({
              open: true,
              severity: "success",
              text: "The reference has been updated.",
            });
            handleCloseReferenceDialog();
          }).catch((e) => {
            setSnackBarProps({
              open: true,
              severity: "warning",
              text: "Failed to update the reference",
            });
          }).finally(() => {
            setSaving(false);
          })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReferenceDialog = (invoice) => {
    setSelectedInvoice(invoice);
    setShowReferenceDialog(true);
  };

  const handleCloseReferenceDialog = () => {
    setShowReferenceDialog(false);
  };

  const handleSaveReference = async (reference) => {
    updateInvoice(selectedInvoice.xeroInvoiceId, reference);
  };

  return (
    <div>
      {showDocumentViewer && pdfUrl && (
        <GeneralDocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setPdfUrl("");
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={pdfUrl}
        />
      )}
      {showReferenceDialog && (
        <EditInvoiceReferenceModal
          invoice={selectedInvoice}
          open={setShowReferenceDialog}
          handleClose={handleCloseReferenceDialog}
          saveReference={handleSaveReference}
          saving={saving}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 30 }}>
              <Checkbox
                checked={selectAll}
                onChange={handleToggleAll}
                color="primary"
              />
            </TableCell>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Subtotal</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mediaInvoices.map((mediaInvoice) => (
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedItems.includes(mediaInvoice.id)}
                  onChange={() => handleToggleItem(mediaInvoice.id)}
                  color="primary"
                />
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {mediaInvoice.invoiceNumber}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {moment(mediaInvoice.date).format("DD/MM/YYYY")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {mediaInvoice.reference !== ""
                    ? mediaInvoice.reference
                    : "-"}
                  {mediaInvoice.status === "DRAFT" ||
                    mediaInvoice.status === "SUBMITTED" ? (
                    <EditIcon
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        marginLeft: 10,
                      }}
                      onClick={(e) => { handleReferenceDialog(mediaInvoice) }}
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{mediaInvoice.status}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {currencyFormat(Number(mediaInvoice.subtotal))}
                </Typography>
              </TableCell>
              <TableCell>
                <div>
                  {mediaInvoice.sentToContact === 1 ? (
                    <div className="chip-short chip green-chip">
                      <Typography variant="caption">Sent</Typography>
                    </div>
                  ) : (<div className="chip-short chip orange-chip">
                    <Typography variant="caption">Not Sent</Typography>
                  </div>)}
                </div>
              </TableCell>
              <TableCell>
                {mediaInvoice.xeroInvoiceId && (
                  <IconButton
                    aria-label="pdf"
                    size="small"
                    onClick={(e) =>
                      handleOpenPdf(e, mediaInvoice.xeroInvoiceId)
                    }
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default MediaCampaignInvoiceTable;
