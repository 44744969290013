import React, { useEffect, useState } from "react";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import {
    Button,
    CircularProgress
} from "@material-ui/core";
import "./equipmentAndTravelPopup.scss";
import TravelReimbursmentOnlyPopup from "./ReimbursmentOnlyPopup";
import axios from "axios";
import { getDistance } from "../../../../../utils/DistanceUtils";
import { connect } from "react-redux";
import { getBudget } from "../../../../../actions/budget";
import Snacky from "../../../../Shared/Snacky";

const TravelReimbursmentOnlyButton = (props) => {
    const {
        scheduleItem,
        projectId,
        scheduleId,
        locations,
    } = props;
    const [showTravelReimbursmentPopup, setShowTravelReimbursmentPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const [travelDistance, settravelDistance] = useState();
    const [travelContribution, settravelContribution] = useState();
    const [accounts, setaccounts] = useState();
    const [showSnackbar, setshowSnackbar] = useState({
        open: false,
        state: null,
    });

    const handleSnacky = (value, state) => {
        setshowSnackbar({
            open: value,
            state: state,
        });
    };

    const handleClick = async () => {
        setLoading(true);
        // const locs = locations.map((l) => {
        //     if (
        //         scheduleItem.startTime >= l.startTime &&
        //         scheduleItem.endTime <= l.endTime
        //     )
        //         return l;
        // });
        const locs = locations.filter((l) => {
            if (scheduleItem.startTime >= l.startTime && scheduleItem.endTime <= l.endTime) {
                return l;
            }
        });
        const distance = await getDistance(locs);
        getValues();
        setShowTravelReimbursmentPopup(true);
        settravelDistance(distance);
    };

    const closePopup = () => {
        setaccounts();
        settravelDistance();
        setShowTravelReimbursmentPopup(false);
        setLoading(false);
    };

    const getValues = () => {
        axios
            .get(`/BudgetItems/GetBudgetForProject/${projectId}`)
            .then(({ data }) => {
                setaccounts(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        axios
            .get("TravelContributions/GetTravelContribution")
            .then(({ data }) => {
                settravelContribution(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <React.Fragment>
            <Snacky
                snackprops={
                    showSnackbar.state === "Success"
                        ? {
                            open: showSnackbar.open,
                            severity: "success",
                            text: "Processed successfully",
                        }
                        : {
                            open: showSnackbar.open,
                            severity: "error",
                            text: "Something went wrong",
                        }
                }
                setSnackBarProps={() => handleSnacky(false)}
            />
            {showTravelReimbursmentPopup && accounts && (
                <TravelReimbursmentOnlyPopup
                    modalState={showTravelReimbursmentPopup}
                    handleCloseModal={() => closePopup()}
                    travelDistance={travelDistance}
                    travelContribution={travelContribution}
                    accounts={accounts}
                    scheduleItem={scheduleItem}
                    disableFields={
                        !(
                            scheduleItem.contractStatus == "Send" ||
                            scheduleItem.contractStatus == "Disabled"
                        )
                    }
                    getBudget={getBudget}
                    handleSnacky={handleSnacky}
                    projectId={projectId}
                    scheduleId={scheduleId}
                />
            )}
            <Button
                variant="contained"
                color="default"
                onClick={() => {
                    handleClick()
                }}
                disabled={loading}
                className={
                    scheduleItem.travelReimbursment === null
                        ? "equipment-button-style-incomplete"
                        : "equipment-button-style-complete"
                }
            >
                {loading ?
                    <span><CircularProgress color="primary" size={15} /></span>
                    : <DirectionsCarIcon />}
            </Button>
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    locations: state.schedules.schedule.scheduleLocations,
});
export default connect(mapStateToProps, {
    getBudget,
})(TravelReimbursmentOnlyButton);

//export default TravelReimbursmentOnlyButton;