/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {  Fab } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import AddContactPopUp from "./NewContact/AddContactPopUp";
import ExistingContactsPopup from "./NewContact/ExistingContactsPopup";
import "date-fns";
import ContractorPopUp from "../ProjectOutputs/PopUps/ContractorPopUp";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setDialogProps } from "../../../actions/dialog";

import "./Schedule.css";
import Snacky from "../../Shared/Snacky";
import InformationDialogy from "../../Shared/InformationDialogy";

const StyledFab = withStyles((theme) => ({
  root: {
    background: "#000000",
    color: "white",
    "&:hover": {
      background: "#000000",
    },
  },
}))(Fab);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

const NewScheduleItem = (props) => {
  const classes = useStyles();
  const [fieldsInvalid, setFieldsInvalid] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [addContactModalState, setAddContactModalState] = useState(false);
  const [providerName, setProviderName] = useState(null);
  const [companyFk, setCompanyId] = useState();
  const [personFk, setPersonId] = useState();
  const [duplicates, setDuplicates] = useState([]);
  const [existingContactsModalState, setExistingContactsModalState] =
    useState(false);
  const [openContractorPopup, setOpenContractorPopUp] = useState(false);
  const [goAhead, setGoAhead] = useState(false);
  const [newContact, setNewContact] = useState([]);
  const [providerObj, setProviderObj] = useState(null);

  const [showTimeError, setShowTimeError] = useState(false);
  const [timeErrorText, setTimeErrorText] = useState("");

  const handleAddContactModal = () => {
    if (addContactModalState) {
      setAddContactModalState(false);
    } else {
      setAddContactModalState(true);
    }
  };

  const handleExistingContactModal = () => {
    console.log("Toggle");
    if (existingContactsModalState) {
      setExistingContactsModalState(false);
    } else {
      setExistingContactsModalState(true);
    }
  };

  const changeProvider = (e, value) => {
    console.log(value);
    setFieldsInvalid(false);
    setInvalidFields([]);
    setProviderObj(value);
    if (value === null) {
      setProviderName(undefined);
      setCompanyId(undefined);
      setPersonId(undefined);
      return;
    }
    setProviderName(value.firstName + " " + value.lastName);
    setPersonId(value.id);
  };

  useEffect(() => {
    if (duplicates.length > 0) {
      setAddContactModalState(false);
      setExistingContactsModalState(true);
    }
  }, [duplicates]);

  const selectExisting = (contact) => {
    selectNewPerson(contact);
    handleExistingContactModal();
  };
  const closePopUps = () => {
    setOpenContractorPopUp(false);
  };
  const selectNewPerson = (value) => {
    changeProvider("", value);
  };
  const handleEditExisting = (contact) => {
    setPersonId(contact.id);
    setOpenContractorPopUp(true);
  };
  const handleContinue = () => {
    setGoAhead(true);
    setExistingContactsModalState(false);
    setAddContactModalState(true);
  };

  return (
    <div className={classes.root}>
      <StyledFab
        size="small"
        style={{ position: "absolute", zIndex: "10", marginTop: "-20px" }}
        onClick={() => handleAddContactModal()}
      >
        <PersonAdd />
      </StyledFab>
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      {showTimeError && (
        <InformationDialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showTimeError,
            titleText: "Woops!",
            contentText: timeErrorText,
            buttonColor: "primary",
            buttonText: "Ok",
          }}
          handleButton={() => {
            setTimeErrorText("");
            setShowTimeError(false);
          }}
        />
      )}
      
      {existingContactsModalState && (
        <ExistingContactsPopup
          handleModal={() => handleExistingContactModal()}
          duplicates={duplicates}
          handleEdit={handleEditExisting}
          modalState={existingContactsModalState}
          setNewContact={setNewContact}
          handleContinue={handleContinue}
          selectExisting={selectExisting}
        />
      )}
      {addContactModalState && (
        <AddContactPopUp
          parent={"NewScheduleItem"}
          handleModal={() => handleAddContactModal()}
          handleAddContactModal={() => handleAddContactModal()}
          modalState={addContactModalState}
          category={props.providerType}
          company={props.projectCFk}
          selectNewPerson={selectNewPerson}
          people={props.people}
          setDuplicates={setDuplicates}
          goAhead={goAhead}
          setGoAhead={setGoAhead}
          goAheadContact={newContact}
          setNewContact={setNewContact}
        />
      )}
      {openContractorPopup && (
        <ContractorPopUp
          handleModal={() => setOpenContractorPopUp(false)}
          modalState={openContractorPopup}
          personId={personFk}
          reloadPersonList={props.reloadPersonList}
          type="NewScheduleItem"
          providerType={props.providerType}
          closePopUps={() => closePopUps()}
          selectExisting={selectExisting}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  people: state.people,
  dialog: state.dialog,
  currentSchedule: state.schedules.schedule,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
  setDialogProps,
})(NewScheduleItem);
