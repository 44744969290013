export const checkIfFinancialDetailsHaveChanged = (originalFinancialDetails, newFinancialDetails) => {
    let hasChanges = false;
    if (originalFinancialDetails.originalIrd !== newFinancialDetails.ird) {
        console.log('originalIrd does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBankAccount !== newFinancialDetails.bankAccount) {
        console.log('originalBankAccount does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalRateHour !== newFinancialDetails.rateHour) {
        console.log('originalRateHour does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalRateHalfDay !== newFinancialDetails.rateHalfDay) {
        console.log('originalRateHalfDay does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalRateDay !== newFinancialDetails.rateDay) {
        console.log('originalRateDay does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalRateWeek !== newFinancialDetails.rateWeek) {
        console.log('originalRateWeek does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalGstRegistered !== newFinancialDetails.gstRegistered) {
        console.log('originalGstRegistered does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalWtrate !== newFinancialDetails.wtrate) {
        console.log('originalWtrate does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcname !== newFinancialDetails.bcname) {
        console.log('originalBcname does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcbankAccount !== newFinancialDetails.bcbankAccount) {
        console.log('originalBcbankAccount does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcird !== newFinancialDetails.bcird) {
        console.log('originalBcird does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcisGstreg !== newFinancialDetails.bcisGstreg) {
        console.log('originalBcisGstreg does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBwtr !== newFinancialDetails.bcwtr) {
        console.log('originalBwtr does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcisWt !== newFinancialDetails.bcisWt) {
        console.log('originalBcisWt does not match.');
        hasChanges = true;
    }
    if (originalFinancialDetails.originalBcorIc !== newFinancialDetails.bcorIc) {
        console.log('originalBcorIc does not match.');
        hasChanges = true;
    }
    if (hasChanges) {
        console.log('There are differences in financial details.');
    }

    return hasChanges;

}